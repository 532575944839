<template>
  <v-container id="relatorios-historico" fluid tag="section">
    <base-material-card
      color="primary"
      inline
      icon="mdi-text-box-check-outline"
      class="ml-4 px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Dashboard - Validações de Indicadores
        </div>
      </template>

      <v-row class="mx-5" justify="end">
        <v-col cols="auto">
          <v-btn color="primary" @click="$router.push('visao-detalhada')">
            <v-icon class="mr-2">mdi-table-of-contents</v-icon>
            Visão Detalhada
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-5" justify="space-around">
        <v-col v-if="relatorioSelecionado !== 'Técnicos'" cols="6" sm="3">
          <input-month
            label="Competência de"
            rules="min:7"
            :minMonth="'2023-01'"
            :selectedMonth="competencia_de"
            @monthSelected="setCompetenciaDe"
          />
        </v-col>
        <v-col cols="6" sm="3">
          <input-month
            label="Competência até"
            rules="min:7"
            :minMonth="'2023-01'"
            :selectedMonth="competencia_ate"
            @monthSelected="setCompetenciaAte"
          />
        </v-col>
        <v-col cols="6" sm="3">
          <v-select
            :items="relatorios"
            :value="relatorioSelecionado"
            @change="setRelatorioSelecionado"
            label="Relatório"
            class="mt-0 pt-1"
            hide-details
          />
        </v-col>
      </v-row>

      <v-tabs
        v-if="relatorioSelecionado == 'Comercial'"
        v-model="tabsTemas"
        center-active
        show-arrows
        color="secondary"
      >
        <v-tab>Geral</v-tab>
        <v-tab v-for="tema in temasComercial" :key="tema.value">
          {{ tema.text }}
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-if="relatorioSelecionado == 'Comercial'"
        v-model="tabsTemas"
      >
        <v-tab-item>
          <charts-geral-comercial
            :temas="temasComercial"
            :valores="Object.values(qtdErrosCriticosPorTema)"
          />
        </v-tab-item>

        <v-tab-item v-for="tema in temasComercial" :key="tema.value">
          <v-row dense justify="center">
            <v-col cols="auto">
              <v-switch
                class="switch-visao-graficos"
                color="primary"
                dense
                hide-details
                false-value="anual"
                true-value="mensal"
                :disabled="loadingDados"
                v-model="visaoGraficos"
              >
                <template #prepend>
                  <label
                    class="v-label"
                    :class="
                      visaoGraficos === 'anual'
                        ? 'text--primary'
                        : 'text--secondary font-weight-light'
                    "
                  >
                    Visão Anual
                  </label>
                </template>
                <template #append>
                  <label
                    class="v-label"
                    :class="
                      visaoGraficos === 'mensal'
                        ? 'text--primary'
                        : 'text--secondary font-weight-light'
                    "
                  >
                    Visão Mensal
                  </label>
                </template>
              </v-switch>
            </v-col>
          </v-row>

          <charts-temas-comercial
            :tema="tema.value"
            :competencias="arrCompetencias"
            :anos="arrAnosCompetencias"
            :meses="arrMesesCompetencias"
            :visaoGrafico="visaoGraficos"
            :dadosIndicadores="dadosIndicadoresComerciais"
          />
        </v-tab-item>
      </v-tabs-items>

      <servicos-v2-dashboard
        v-else-if="relatorioSelecionado === 'Serviços'"
        ref="servicosV2Dashboard"
        :competencia_de="competencia_de"
        :competencia_ate="competencia_ate"
        :competencias="arrCompetencias"
        :anos="arrAnosCompetencias"
        :meses="arrMesesCompetencias"
        :loadingDados="loadingDados"
        @updateLoadingDados="loadingDados = $event"
      />

      <tecnicos-dashboard
        v-else-if="relatorioSelecionado === 'Técnicos'"
        ref="tecnicosDashboard"
        :competencia="competencia_ate"
        :loadingDados="loadingDados"
        @updateLoadingDados="loadingDados = $event"
      />

      <v-overlay :value="loadingDados" :opacity="0.85">
        <v-progress-circular indeterminate color="primary" size="64" />
      </v-overlay>
    </base-material-card>
  </v-container>
</template>

<script>
  import dayjs from "dayjs";
  import IndicadoresComerciaisV2Service from "@/services/IndicadoresComerciaisV2Service.js";
  import { mapState, mapActions } from "vuex";

  export default {
    name: "ValidacoesDashboard",
    components: {
      InputMonth: () => import("@/components/general/InputMonth.vue"),
      ChartsGeralComercial: () =>
        import("@/components/validacoes/Charts/ChartsGeralComercial.vue"),
      ChartsTemasComercial: () =>
        import("@/components/validacoes/Charts/ChartsTemasComercial.vue"),
      ServicosV2Dashboard: () =>
        import("@/components/validacoes/ServicosV2Dashboard.vue"),
      TecnicosDashboard: () =>
        import("@/components/validacoes/TecnicosDashboard.vue"),
    },
    data: () => ({
      relatorios: ["Comercial", "Serviços", "Técnicos"],
      temasComercial: [
        { value: "Faturamento", text: "Faturamento" },
        { value: "LigacaoComObras", text: "Incorporação de Rede" },
        { value: "DanosEletricos", text: "Danos Elétricos" },
        { value: "SubstituicaoDeMedidores", text: "Substituição de Medidores" },
        { value: "EstruturaDeAtendimento", text: "Estrutura de Atendimento" },
        { value: "RecuperacaoDeReceita", text: "Recuperação de Receita" },
        { value: "SuspensaoIndevida", text: "Suspensão Indevida" },
      ],
      tabsTemas: 1,
      visaoGraficos: "mensal",
      dadosIndicadoresComerciais: {},
      qtdErrosCriticosPorTema: [],
      loadingDados: true,
    }),
    computed: {
      ...mapState("parametrosPainelValidacoes", [
        "competencia_de",
        "competencia_ate",
        "relatorioSelecionado",
      ]),
      arrCompetencias() {
        const startDate = this.competencia_de || dayjs().format("YYYY-MM");
        const endDate = this.competencia_ate || dayjs().format("YYYY-MM");

        let start = startDate.split("-");
        let end = endDate.split("-");
        let startYear = parseInt(start[0]);
        let endYear = parseInt(end[0]);
        let dates = [];

        for (let i = startYear; i <= endYear; i++) {
          let endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
          let startMon = i === startYear ? parseInt(start[1]) - 1 : 0;

          for (
            let j = startMon;
            j <= endMonth;
            j = j > 12 ? j % 12 || 11 : j + 1
          ) {
            let month = j + 1;
            let displayMonth = month < 10 ? "0" + month : month;
            dates.push([displayMonth, i].join("/"));
          }
        }

        return dates;
      },
      arrMesesCompetencias() {
        return [
          ...new Set(
            this.arrCompetencias
              ?.map((competencia) => competencia.split("/")[0])
              .sort()
          ),
        ];
      },
      arrAnosCompetencias() {
        return [
          ...new Set(
            this.arrCompetencias
              ?.map((competencia) => competencia.split("/")[1])
              .sort()
          ),
        ];
      },
    },
    beforeMount() {
      this.setRangeCompetencias();
    },
    mounted() {
      this.atualizaDadosDosGraficos();
    },
    methods: {
      ...mapActions("parametrosPainelValidacoes", [
        "setRangeCompetencias",
        "setCompetenciaDe",
        "setCompetenciaAte",
        "setRelatorioSelecionado",
      ]),
      atualizaDadosDosGraficos() {
        switch (this.relatorioSelecionado) {
          case "Comercial":
            this.getQtdErrosCriticosPorTema();
            this.getDadosIndicadoresComerciais();
            break;
          case "Serviços":
            this.$refs?.servicosV2Dashboard?.getDados(
              this.competencia_de,
              this.competencia_ate,
              this.arrCompetencias,
              this.arrAnosCompetencias
            );
            break;
          case "Técnicos":
            this.$refs?.tecnicosDashboard?.getDados(this.competencia_ate);
            break;
        }
      },
      getQtdErrosCriticosPorTema() {
        this.loadingDados = true;

        IndicadoresComerciaisV2Service.getQtdErrosCriticosPorTema(
          this.competencia_de,
          this.competencia_ate
        )
          .then((res) => {
            this.qtdErrosCriticosPorTema = res[0];
          })
          .catch((err) => {
            throw err;
          })
          .finally(() => (this.loadingDados = false));
      },
      getDadosIndicadoresComerciais() {
        if (this.visaoGraficos === "mensal") {
          return this.getDadosIndicadoresComerciaisPorCompetencia();
        } else if (this.visaoGraficos === "anual") {
          return this.getDadosIndicadoresComerciaisPorAno();
        }
      },
      getDadosIndicadoresComerciaisPorCompetencia() {
        this.loadingDados = true;

        IndicadoresComerciaisV2Service.getDadosPorCompetencia(
          this.competencia_de,
          this.competencia_ate
        )
          .then((res) => {
            this.dadosIndicadoresComerciais = res;
          })
          .catch((err) => {
            throw err;
          })
          .finally(() => (this.loadingDados = false));
      },
      getDadosIndicadoresComerciaisPorAno() {
        this.loadingDados = true;

        IndicadoresComerciaisV2Service.getDadosPorAno(
          this.competencia_de,
          this.competencia_ate
        )
          .then((res) => {
            this.dadosIndicadoresComerciais = res;
          })
          .catch((err) => {
            throw err;
          })
          .finally(() => (this.loadingDados = false));
      },
    },
    watch: {
      visaoGraficos() {
        this.getDadosIndicadoresComerciais();
      },
      competencia_de() {
        this.atualizaDadosDosGraficos();
      },
      competencia_ate() {
        this.atualizaDadosDosGraficos();
      },
      relatorioSelecionado() {
        this.atualizaDadosDosGraficos();
      },
    },
  };
</script>

<style>
  .switch-visao-graficos .v-input__control {
    width: 32px !important;
  }
  .switch-visao-graficos
    .v-input--selection-controls__input
    .v-input--switch__track,
  .switch-visao-graficos
    .v-input--selection-controls__input
    .v-input--switch__thumb {
    color: #003a63 !important;
  }
</style>
